import React, { useState } from "react";
import { Link, useHistory ,useLocation } from "react-router-dom";

import { useSelector } from 'react-redux';

// CSS
import "./layout.css";

// SVGs
import SideBarUserPreview from "../assets/sidebar-profile-preview.svg";
import BannerSvg from "../assets/assets_svg/banner.svg";
import WhatsappSvg from "../assets/assets_svg/whatsapp.svg";
import taskSvg from "../assets/assets_svg/task.svg";
import usersSvg from "../assets/assets_svg/users.svg";
import speedometerSvg from "../assets/assets_svg/speedometer.svg";
import termsConditionsSvg from "../assets/assets_svg/terms-conditions.svg";
import notificationSvg from "../assets/assets_svg/notification.svg";

const Sidebar = () => {
  
  const admin = useSelector((state) => state?.auth?.user);
  const location = useLocation();

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-logo">KOIN KUBER</div>
        <div className="side-profile-icon-info text-center">
          <div className="profile-pic">
            <img src={SideBarUserPreview} alt="user-preview" />
          </div>
          <Link className="user-name">{admin?.fullname}</Link>
        </div>
        <div className="sidebar-menus">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to={"/dashboard"} className={`menu-link ${location?.pathname === "/dashboard" && 'active-link'}`}>
                <img src={speedometerSvg} alt="speedometer icon" />
                <p>Dashboard</p>
              </Link>
            </li>          
            <li className="menu-item">
              <Link to={"/whatsapp-share-text"} className={`menu-link ${(location?.pathname === "/whatsapp-share-text" || location?.pathname === "/add-whatsapp-text") && 'active-link'}`}>
                <img src={WhatsappSvg} alt="setting icon" />
                <p>WhatsApp Share Text</p>
              </Link>
            </li>            
            <li className="menu-item">
              <Link to={"/task"} className={`menu-link ${(location?.pathname === "/task" || location?.pathname === "/add-task") && 'active-link'}`}>
                <img src={taskSvg} alt="setting icon" />
                <p>Task</p>
              </Link>
            </li>            
            <li className="menu-item">
              <Link to={"/users"} className={`menu-link ${location?.pathname === "/shipping-address" && 'active-link'}`}>
                <img src={usersSvg} alt="setting icon" />
                <p>Users</p>
              </Link>
            </li>            
            <li className="menu-item">
              <Link to={"/terms-and-conditions"} className={`menu-link ${(location?.pathname === "/terms-and-conditions") && 'active-link'}`}>
                <img src={termsConditionsSvg} alt="setting icon" />
                <p>Terms & Conditions</p>
              </Link>
            </li>            
            <li className="menu-item">
              <Link to={"/notification"} className={`menu-link ${(location?.pathname === "/notification") && 'active-link'}`}>
                <img src={notificationSvg} alt="setting icon" />
                <p>Notification</p>
              </Link>
            </li>            
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
