import React, { useEffect, useState } from 'react';
import UserDefaultSvg from '../../assets/assets_svg/userdefaultimg.svg';
import { Link, useNavigate, useSearchParams  } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";

import { getApi, postApi } from "../../Helpers/Axios";

const Addform = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewUser, setPreviewUser] = useState(null);

    useEffect(() => {
        if(searchParams.get('edit_id')) {
            handleEdit()
        }
    }, [searchParams.get('edit_id')])

    const handleEdit = async() => {
        let url = `get-task?edit_id=${searchParams.get('edit_id')}`;
        await getApi(url, true).then((response) => {
            if(response.status === 200) {
                setInitialValueData(response.data)
            }
        })
    }

    const [initialValues, setInitialValueData] = useState ({
        full_name: null,
        phone_number: null,
        win_price: null,
        insta_id: null,
        payment_gateway: null,
        payment_number: null,
        status: 0,
    });
    
    const validationSchema = Yup.object({
        task: Yup.string()
            .required("Task is required.")
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.status = values.status ? 1 : 0;
            let url = searchParams.get('edit_id') ? `add-task?edit_id=${searchParams.get('edit_id')}` : 'add-task';
            await postApi(url, values, true)
                .then((response) => {
                    if(response.status === 200) {
                        navigate("/task");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    const handleImageChangeUser = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setFieldValue("banner", file);
            setPreviewUser(URL.createObjectURL(file));
        }
    };

    return (
        <div>
            <div>
                <div>
                    <p className='breadcrumbs'><Link to='/dashboard'><span>Dashboard</span></Link> / <Link to='/task'><span>Task</span></Link> / <span>Add</span></p>
                </div>
            </div>
            <div>
                <form method='post' className='add-detail-main' onSubmit={handleSubmit} encType='multipart/form-data'>
                    <div className='user-upload'>
                        <div className='user-upload-img'>
                            {!previewUser ? 
                            <img src={UserDefaultSvg} alt='default img' className='user-default-img' />:
                            <img src={previewUser} alt='Add phone Icon' className='user-img-preview'></img>
                        }
                            <input type='file' id="upload" className='d-none' onChange={(e) => handleImageChangeUser(e)} />
                        </div>
                        <label type='button' for='upload' className='user-upload-btn'>Upload</label>
                    </div>
                    <div class="form-with-lable">
                        <label class="mt-1 form_label">Full Name</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Full Name' name='full_name' onChange={handleChange} onBlur={handleBlur} value={values?.full_name} />                        
                        </div>
                    </div>
                    {(errors?.full_name && touched?.full_name) ? <span className='require-text'>{errors?.full_name}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Phone Number</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Phone Number' name='phone_number' onChange={handleChange} onBlur={handleBlur} value={values?.phone_number} />                        
                        </div>
                    </div>
                    {(errors?.phone_number && touched?.phone_number) ? <span className='require-text'>{errors?.phone_number}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Payment Gateway</label>
                        <div class="input-box">
                            <input type='text' placeholder='Payment Gateway' name='payment_gateway' onChange={handleChange} onBlur={handleBlur} value={values?.payment_gateway} />                        
                        </div>
                    </div>
                    {(errors?.payment_gateway && touched?.payment_gateway) ? <span className='require-text'>{errors?.payment_gateway}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Payment Gateway</label>
                        <div class="input-box">
                            <input type='text' placeholder='Payment Gateway' name='payment_gateway' onChange={handleChange} onBlur={handleBlur} value={values?.payment_number} />                        
                        </div>
                    </div>
                    {(errors?.payment_number && touched?.payment_number) ? <span className='require-text'>{errors?.payment_number}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Win Price</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Win Price' name='win_price' onChange={handleChange} onBlur={handleBlur} value={values?.win_price} />                        
                        </div>
                    </div>
                    {(errors?.win_price && touched?.win_price) ? <span className='require-text'>{errors?.win_price}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Instgram ID</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Instgram ID' name='insta_id' onChange={handleChange} onBlur={handleBlur} value={values?.insta_id} />                        
                        </div>
                    </div>
                    {(errors?.insta_id && touched?.insta_id) ? <span className='require-text'>{errors?.insta_id}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">UPI ID</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter UPI ID' name='upi_id' onChange={handleChange} onBlur={handleBlur} value={values?.upi_id} />                        
                        </div>
                    </div>
                    {(errors?.upi_id && touched?.upi_id) ? <span className='require-text'>{errors?.upi_id}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Paypal ID</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Paypal ID' name='paypal_id' onChange={handleChange} onBlur={handleBlur} value={values?.paypal_id} />                        
                        </div>
                    </div>
                    {(errors?.paypal_id && touched?.paypal_id) ? <span className='require-text'>{errors?.paypal_id}</span> : null}
                    <div class="form-with-lable mt-2">
                        <label class="mt-1 form_label">Crypto ID</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Crypto ID' name='paypal_id' onChange={handleChange} onBlur={handleBlur} value={values?.crypto_id} />                        
                        </div>
                    </div>
                    {(errors?.crypto_id && touched?.crypto_id) ? <span className='require-text'>{errors?.crypto_id}</span> : null}
                    <div className='text-center mt-4'>
                        <button type='submit' className='theme-btn px-4'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Addform