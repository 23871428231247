
// SVGs
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {Modal, Table} from 'react-bootstrap';

// Helpers
import { getApi, postApi } from "../../Helpers/Axios";

// SVGs
import ExportSvg from "../../assets/assets_svg/export.svg";
import LightExportSvg from "../../assets/assets_svg/lightexport.svg";
import WinnerSvg from "../../assets/assets_svg/winner.svg";
import WinnerYellowSvg from "../../assets/assets_svg/winner_yellow.svg";
import EditSvg from "../../assets/assets_svg/edit.svg";

const UserModel = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);

    const [shipAddrData, setShipAddrData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isExportStatus, setIsExportStatus] = useState(null);
    
    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');
    
    useEffect(() => {
        setIsLoadingData(true);
        fetchUsers();
    }, [currentPage, searchData])

    const fetchUsers = async () => {
        let url = searchData !== '' ? `users?page=${currentPage}&search=${searchData}` : `users?page=${currentPage}`
        await getApi(url, true).then(async (response) => {
            if(response.status === 200) {
                setShipAddrData(response?.data);
                setDataCount(response?.dataCount);
                setTotalPage(response?.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }    

    const handleClose = () => {
        setModalShow(false);
    }

    useEffect(()=> {
        if(isExportStatus !== null) {
            handleExport();
        }
    }, [isExportStatus]);

    const handleExport = async () => {
        let url = `export-users?all=${isExportStatus}`;
        await getApi(url, true).then(async (response) => {
            if(response.status === 200) {
                // Fetch the CSV file
                const responseVal = await fetch(`${process.env.REACT_APP_UPLOAD_URL}exports_file/${response?.data}`);
                const blob = await responseVal.blob();
                
                // Create a link element
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${response?.data}.csv`; // You can set a custom filename here

                // Append the link to the body (not visible)
                document.body.appendChild(link);
                
                // Programmatically click the link to trigger the download
                link.click();

                // Clean up
                document.body.removeChild(link);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleWinner = async (id, is_win) => {
        let url = `winner-user?winner_id=${id}`;
        let values = {
            is_winner: parseInt(is_win) === 0 ? 1 :0
        }
        await postApi(url, values, true)
                .then((response) => {
                    if(response.status === 200) {
                        setShipAddrData((prevWinnerData) => 
                            prevWinnerData.map(winner =>
                                winner.id === id ? { ...winner, is_winner: parseInt(is_win) === 0 ? 1 :0 } : winner
                            )
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
    }

    return(
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard /</Link></span> Users</p>
                <div className="d-flex justify-content-between align-items-center">
                    <input type="search" placeholder="Searching...." className="search-input me-3" name="title" value={searchData} onChange={(e) => setSearchData(e.target.value)} />
                    <button className="theme-icon-btn float-end px-3" onClick={() => setModalShow(true)}><img src={ExportSvg} alt="setting icon" />Export</button>
                </div>
            </div>
            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Full Name</th>
                            <th>Phone Number</th>
                            <th>Payment Gateway</th>
                            <th>Payment Phone Number</th>
                            <th>UPI ID</th>
                            <th>Paypal ID</th>
                            <th>Crypto ID</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? shipAddrData?.length ? shipAddrData?.map((val, key) => {
                                return(
                                    <tr key={key}>
                                        <td>{val?.id}</td>
                                        <td>{val?.full_name}</td>
                                        <td>{val?.phone_number}</td>
                                        <td>{val?.payment_gateway}</td>
                                        <td>{val?.payment_number}</td>
                                        <td>{val?.upi_id}</td>
                                        <td>{val?.paypal_id}</td>
                                        <td>{val?.crypto_id}</td>
                                        <td>
                                            <div className="win-icons">
                                                <img src={EditSvg} alt="winner icon" onClick={() => navigate(`/edit-user?edit_id=${val.id}`)}/> &nbsp;&nbsp;|&nbsp;&nbsp;
                                                {parseInt(val?.is_winner) === 0 ? <img src={WinnerSvg} alt="winner icon" onClick={() => {handleWinner(val?.id, parseInt(val?.is_winner))}}/> :
                                                <img src={WinnerYellowSvg} alt="winner icon" onClick={() => {handleWinner(val?.id, parseInt(val?.is_winner))}}/>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={9}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={9} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
            <div className='footer-pagination'>
                <ul className='paginations d-flex justify-content-end list-unstyled gap-2'>
                    {dataCount > 10 && (
                        <>
                            <li className={`pages-item ${currentPage === 1 ? 'disabled' : ''}`} disabled={currentPage < 1 ? 'disabled' :false} onClick={() => handlePageChange(1)}>{'<<'}</li>
                            <li className={`pages-item ${currentPage === 1 ? 'disabled' : ''}`} disabled={currentPage < 1 ? 'disabled':false} onClick={() => handlePageChange(currentPage - 1)}>{'<'}</li>
                        </>
                    )}
                    {
                        dataCount > 10 && Array.apply(null, Array(totalPage)).map((val, key) => {
                            return <li key={key} className={`pages-item ${(currentPage === (key + 1) && currentPage !== 1) ? 'active' : ''}`} onClick={() => handlePageChange(key + 1)}>{key + 1}</li>
                        })
                    }
                    {dataCount > 10 && (
                        <>
                            <li className={`pages-item ${currentPage === parseInt(totalPage) ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage + 1)}>{'>'}</li>
                            <li className={`pages-item ${currentPage === parseInt(totalPage) ? 'disabled' : ''}`} onClick={() => handlePageChange(totalPage)}>{'>>'}</li>
                        </>
                    )}
                </ul>
            </div>

            <Modal show={modalShow} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="theme-mobel-header">
                        <p className="header-title">Export Address</p>
                    </div>
                    <div className="theme-mobel-body">
                        <div className={`export-btn mb-4`} onClick={() => {setIsExportStatus(0)}}>
                            <span>
                                <img src={LightExportSvg} alt="export svg" />
                            </span>
                            <p>Full Name And Mobile Number in CSV format</p>
                        </div>
                        <div className="export-btn" onClick={() => {setIsExportStatus(1)}}>
                            <span>
                                <img src={LightExportSvg} alt="export svg" />
                            </span>
                            <p>All Data CSV format</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserModel;