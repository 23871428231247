import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Redux store
import {store} from './Helpers/Redux/store';
// Redux store ---- End

// Components
import Layout from "./Layout/index";
import SignIn from './components/Auth/SignIn';
import Dashboard from './components/Dashboard';
import AddBanner from './components/Banner/addbannerform';
import WhatsappText from './components/Whatsapp';
import AddWhatsappBtn from './components/Whatsapp/addwhatsappform';
import Task from './components/Task';
import Addtask from './components/Task/addtask';
import Users from './components/Users';
import AddUsers from './components/Users/adduser';
import TermsConditions from './components/TermsConditions';
import Notification from './components/Notification';
// Components ---- End

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Layout><Dashboard /></Layout>,
  },
  {
    path: "/add-banner",
    element: <Layout><AddBanner /></Layout>,
  },
  {
    path: "/whatsapp-share-text",
    element: <Layout><WhatsappText /></Layout>,
  },
  {
    path: "/add-whatsapp-text",
    element: <Layout><AddWhatsappBtn /></Layout>,
  },
  {
    path: "/task",
    element: <Layout><Task /></Layout>,
  },
  {
    path: "/add-task",
    element: <Layout><Addtask /></Layout>,
  },
  {
    path: "/users",
    element: <Layout><Users /></Layout>,
  },
  {
    path: "/edit-user",
    element: <Layout><AddUsers /></Layout>,
  },
  {
    path: "/terms-and-conditions",
    element: <Layout><TermsConditions /></Layout>,
  },
  {
    path: "/notification",
    element: <Layout><Notification /></Layout>,
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));  
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);